import React, { useEffect, useState } from 'react';
import { ConfigurationContextType } from './Types/ConfigurationContextType';
import { ProviderProps } from './Types/ProviderProps';
import ProdPipelineApi from '../api/ProdPipelineApi';
import { ConfigurationDto } from '../api/types/GetConfigurationResponseDto';
import { PipelineConfigurationDto } from '../api/types/GetPipelineConfigurationResponseDto';
import { PresetDto } from '../api/types/GetPresetConfigurationResponseDto';

const ConfigurationContext =
  React.createContext<ConfigurationContextType | null>(null);

export const ConfigurationProvider: React.FC<ProviderProps> = ({
  children,
}) => {
  const [configurationLoading, setConfigurationLoading] = useState(true);
  const [pipelineVersionLoading, setPipelineVersionLoading] = useState(true);
  const [configuration, setConfiguration] = useState<ConfigurationDto>();
  const [pipelineVersions, setPipelineVersions] = useState<
    Map<string, PipelineConfigurationDto>
  >(new Map());
  const [presets, setPresets] = useState<Map<string, PresetDto>>(new Map());

  useEffect(() => {
    loadConfiguration();
    // eslint-disable-next-line
  }, []);

  const loadConfiguration = () => {
    (async () => {
      setConfigurationLoading(true);
      const configurationResponse = await ProdPipelineApi.getConfiguration();
      if (configurationResponse.data) {
        setConfiguration(configurationResponse.data.configuration);
        loadPipelineVersion(configurationResponse.data.configuration.default);
      }
      setConfigurationLoading(false);
    })();
  };

  const loadPipelineVersion = async (pipelineKey: string) => {
    setPipelineVersionLoading(true);
    const pipelineConfigurationResponse =
      await ProdPipelineApi.getPipelineConfiguration(pipelineKey);
    if (pipelineConfigurationResponse.data) {
      let pipeline = pipelineConfigurationResponse.data.pipeline;
      setPipelineVersions((oldPipelines) =>
        new Map(oldPipelines).set(pipelineKey, pipeline),
      );
      setPipelineVersionLoading(false);
      return pipeline;
    }
    setPipelineVersionLoading(false);
  };

  const loadPreset = async (presetName: string) => {
    setPipelineVersionLoading(true);
    const pipelineConfigurationResponse = await ProdPipelineApi.getPreset(
      presetName,
    );
    if (pipelineConfigurationResponse.data) {
      let preset = pipelineConfigurationResponse.data.preset;
      setPresets((oldPresets) => new Map(oldPresets).set(presetName, preset));
      setPipelineVersionLoading(false);
      return preset;
    }
    setPipelineVersionLoading(false);
  };

  const getPipelineVersion = async (pipelineKey: string) => {
    if (!pipelineVersions.has(pipelineKey)) {
      return await loadPipelineVersion(pipelineKey);
    } else return pipelineVersions.get(pipelineKey);
  };

  const getPreset = async (presetName: string) => {
    if (!presets.has(presetName)) {
      return await loadPreset(presetName);
    } else return presets.get(presetName);
  };

  const addPreset = (name: string, data: {}, pipelineVersion: string) => {
    ProdPipelineApi.savePreset(name, data, pipelineVersion);
    if (configuration && !configuration.presets.includes(name))
      setConfiguration({
        ...configuration,
        presets: [...configuration.presets, name],
      });
    if (presets) {
      setPresets(
        new Map(presets).set(name, {
          name: name,
          data: data,
          pipeline_version: pipelineVersion,
        }),
      );
    }
  };

  const removePreset = (presetName: string) => {
    if (configuration && configuration.presets.includes(presetName))
      setConfiguration({
        ...configuration,
        presets: configuration.presets.filter((v) => {
          return v !== presetName;
        }),
      });
    if (presets) {
      let tempPresets = new Map(presets);
      tempPresets.delete(presetName);
      setPresets(new Map(tempPresets));
    }
    ProdPipelineApi.removePreset(presetName);
  };

  return (
    <ConfigurationContext.Provider
      value={{
        // @ts-ignore
        configuration,
        configurationLoading,
        // @ts-ignore
        getPipelineVersion,
        // @ts-ignore
        getPreset,
        pipelineVersionLoading,
        addPreset,
        removePreset,
      }}
    >
      {children}
    </ConfigurationContext.Provider>
  );
};

export default ConfigurationContext;
