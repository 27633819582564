import React, { useEffect, useState } from 'react';
import { ProviderProps } from './Types/ProviderProps';
import { RunsContextType } from './Types/RunsContextType';
import { RunDto } from '../api/types/GetRunsResponseDto';
import ProdPipelineApi from '../api/ProdPipelineApi';

const RunsContext = React.createContext<RunsContextType | null>(null);

export const RunsProvider: React.FC<ProviderProps> = ({ children }) => {
  const [runsLoading, setRunsLoading] = useState(false);
  const [runs, setRuns] = useState<Map<string, RunDto>>(new Map());
  const [lastRunKey, setLastRunKey] = useState<null | any>(null);

  useEffect(() => {
    loadRuns();
    // eslint-disable-next-line
  }, []);

  const loadRuns = (extend?: boolean) => {
    (async () => {
      setRunsLoading(true);
      let prevRuns = extend ? runs : new Map();
      setRuns(new Map());
      const runsResponse = await ProdPipelineApi.getRuns(
        extend ? lastRunKey : null,
      );
      if (runsResponse.data) {
        const responseRuns = runsResponse.data.runs;
        let tempRuns: Map<string, RunDto> = prevRuns;
        if (responseRuns) {
          responseRuns.forEach((run) => {
            tempRuns.set(run.id, run);
          });
          setRuns(tempRuns);
          setLastRunKey(runsResponse.data.lastKey);
        }
      }
      setRunsLoading(false);
    })();
  };

  const deleteRuns = async (selectedRuns: string[]) => {
    setRunsLoading(true);
    await ProdPipelineApi.deleteRuns(selectedRuns);
    loadRuns();
  };

  const restartRuns = async (selectedRuns: string[], userName: string) => {
    setRunsLoading(true);
    await ProdPipelineApi.restartRuns(selectedRuns, userName);
    loadRuns();
  };

  return (
    // @ts-ignore
    <RunsContext.Provider
      value={{
        loadRuns,
        runs,
        runsLoading,
        deleteRuns,
        restartRuns,
        lastRunKey,
      }}
    >
      {children}
    </RunsContext.Provider>
  );
};

export default RunsContext;
