import styled from 'styled-components';
import ColorPickerControl from '../ColorPickerControl';
import React from 'react';
import CheckBoxControl from '../CheckBoxControl';
import { HandleType } from '../../../configs';

interface SkinColorControlProps {
  description: string;
  id: string;
  value: number[] | null;
  onChange: { (field: HandleType): void };
}

const SkinColorControl = (props: SkinColorControlProps) => {
  const defaultSkinColor: number[] = [168, 102, 46];
  const setColor = (color: number[] | null) => {
    let field = {
      name: props.id,
      value: color,
    };
    props.onChange(field);
  };
  return (
    <Container>
      <ColorPickerControl
        disabled={!props.value}
        headColor={props.value}
        setSkinColor={setColor}
      />
      <CheckBoxControl
        description={props.description}
        id={'detectSkinFromHead'}
        onChange={(event) => {
          setColor(event.value ? null : defaultSkinColor);
        }}
        checked={!props.value}
      />
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export default SkinColorControl;
