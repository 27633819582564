import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React from 'react';

interface DialogControlProps {
  isOpen: boolean;
  title: string;
  description: string;
  setIsOpen: { (selection: boolean): void };
  approveAction: { (): void };
}

const DialogControl = (props: DialogControlProps) => {
  const handleClose = () => {
    props.setIsOpen(false);
  };
  const handleApprove = () => {
    props.setIsOpen(false);
    props.approveAction();
  };

  return (
    <Dialog
      open={props.isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={handleClose}>
          Cancel
        </Button>
        <Button color="inherit" onClick={handleApprove} autoFocus>
          Approve
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogControl;
