import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import NewRunModal from '../Modals/NewRun';
import Header from '../Header';
import RunsContext from '../../context/RunsContext';
import { RunsContextType } from '../../context/Types/RunsContextType';
import RunTable from '../Tables/RunTable';

interface RunsProps {
  userName: string;
  isAdmin: boolean;
}

const Runs = (props: RunsProps) => {
  const { runsLoading, deleteRuns, restartRuns, loadRuns, runs, lastRunKey } =
    useContext(RunsContext) as RunsContextType;
  const [disabledDelete, setDisabledDelete] = useState(true);
  const [disabledEdit, setDisabledEdit] = useState(true);
  const [disableRestart, setDisabledRestart] = useState(true);
  const [selectedRuns, setSelectedRuns] = useState<string[]>([]);
  const [showRegister, setShowRegister] = useState(false);

  useEffect(() => {
    let createdRuns = 0;
    let failedRuns = 0;
    let successRuns = 0;
    selectedRuns.forEach((runId) => {
      if (runs.get(runId)?.state === 'created') {
        createdRuns += 1;
      } else if (runs.get(runId)?.state === 'failed') {
        failedRuns += 1;
      } else if (runs.get(runId)?.state === 'finished') {
        successRuns += 1;
      }
    });
    if (
      createdRuns + failedRuns > 0 &&
      createdRuns + failedRuns === selectedRuns.length
    )
      setDisabledDelete(false);
    else setDisabledDelete(true);

    if (
      failedRuns + successRuns > 0 &&
      failedRuns + successRuns === selectedRuns.length
    )
      setDisabledRestart(false);
    else setDisabledRestart(true);

    if (failedRuns + successRuns === 1 && selectedRuns.length === 1)
      setDisabledEdit(false);
    else setDisabledEdit(true);
    // eslint-disable-next-line
  }, [selectedRuns]);

  const showRegisterWindow = () => {
    setShowRegister(true);
  };

  const closeModal = (selection: boolean, reloadData: boolean) => {
    setShowRegister(selection);
    if (reloadData) {
      loadRuns();
    }
  };

  const _restartRuns = () => {
    restartRuns(selectedRuns, props.userName);
  };

  const _deleteRuns = () => {
    deleteRuns(selectedRuns);
  };

  return (
    <Container>
      <Header
        userName={props.userName}
        name={'Prod Pipeline'}
        isAdmin={props.isAdmin}
      />
      <Wrapper>
        {showRegister && (
          <NewRunModal
            closeModal={closeModal}
            userName={props.userName}
            runId={!disabledEdit ? selectedRuns[0] : null}
            isNewRun={
              selectedRuns.length === 1 &&
              runs.get(selectedRuns[0])?.state === 'finished'
            }
          />
        )}
        <RunTable
          isLoading={runsLoading}
          data={runs}
          reloadData={loadRuns}
          deleteRuns={_deleteRuns}
          createRun={showRegisterWindow}
          setSelectedRuns={setSelectedRuns}
          disabledDelete={disabledDelete}
          disabledEdit={disabledEdit}
          disableRestart={disableRestart}
          restartRuns={_restartRuns}
          rowCount={lastRunKey ? 9999 : runs.size}
        />
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  margin: auto;
  max-width: 1440px;
`;

const Wrapper = styled.div`
  padding: 1%;

  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-radius: 10px;
`;

export default Runs;
