import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Header from '../../Header';
import SelectControl from '../../Controls/SelectControl';
import {
  BottomNavigation,
  BottomNavigationAction,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import AccordionControl from '../../Controls/AccordionControl';
import AddIcon from '@mui/icons-material/Add';
import { UploadFile } from '../../Controls/SelectFiles';
import LoadingIcon from '../../Controls/LoadingIcon';
import DeleteIcon from '@mui/icons-material/Delete';
import TextFieldControl from '../../Controls/TextFieldControl';
import ConfigurationContext from '../../../context/ConfigurationContext';
import { ConfigurationContextType } from '../../../context/Types/ConfigurationContextType';
import {
  AnyObject,
  Components,
  DynamicComponentProps,
  HandleType,
} from '../../../configs';
import InfoControl from '../../Controls/InfoWindow';
import Modal from '@mui/material/Modal';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import axios from 'axios';
import ProdPipelineApi from '../../../api/ProdPipelineApi';

interface CreatePipelineTaskProps {
  userName: string;
  isAdmin: boolean;
}

const CreatePipelineTask = (props: CreatePipelineTaskProps) => {
  const {
    pipelineVersionLoading,
    getPipelineVersion,
    configuration,
    configurationLoading,
  } = useContext(ConfigurationContext) as ConfigurationContextType;
  const [selectedPipelineVersion, setSelectedPipelineVersion] = useState(
    configuration.default,
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isComplexTask, setIsComplexTask] = useState(true);

  const [stage, setStage] = useState('ml');
  const requires = [stage].concat(
    isComplexTask ? configuration.services[stage].requires : [],
  );
  const [isRenderInputsExtended, setIsRenderInputsExtended] = useState(false);
  const [isMlInputsExtended, setIsMlInputsExtended] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);

  const [structure, setStructure] = useState<any[]>([]);
  // TODO: make state as Map
  const [state, setState] = useState({});
  const [defaultState, setDefaultState] = useState({});
  const [accordionsState, setAccordionsState] = useState({});
  const [infoOpened, setInfoOpened] = useState(false);
  const [infoMessage, setInfoMessage] = useState('');
  const [infoType, setInfoType] = useState<'success' | 'warning' | 'error'>(
    'success',
  );
  const [sourceLink, setSourceLink] = useState('');
  const [selectedPresetPipelineVersion, setSelectedPresetPipelineVersion] =
    useState<null | string>(null);

  useEffect(() => {
    if (selectedPipelineVersion !== '') buildForm(selectedPipelineVersion);
    // eslint-disable-next-line
  }, [selectedPipelineVersion]);

  const buildForm = (pipelineKey: string) => {
    getPipelineVersion(pipelineKey).then((pipelineVersion) => {
      let tempExpandedModals = {};
      pipelineVersion.components.forEach((component: any) => {
        if (component.type === 'accordion') {
          // @ts-ignore
          tempExpandedModals[component.data.id] = component.data.expanded;
        }
      });
      setAccordionsState(tempExpandedModals);
      setStructure(pipelineVersion.components);
      if (selectedPresetPipelineVersion !== pipelineKey) {
        setSelectedPresetPipelineVersion(null);
        setState(pipelineVersion.data);
        setDefaultState(pipelineVersion.data);
      }
    });
  };

  const render = (component: DynamicComponentProps, componentKey: number) => {
    let componentType = component.type;
    const TagName = Components[componentType as keyof AnyObject];
    let params = {};
    if (component.data.params) {
      component.data.params.forEach((param: HandleType) => {
        // @ts-ignore
        params[param.name] = state[param.value];
      });
    }
    if (componentType === 'accordion') {
      let componentId = component.data.id;
      // @ts-ignore
      params['expanded'] = accordionsState[componentId];
    }
    let handleFunction =
      componentType === 'accordion' ? handleExpand : handleOnChange;
    let renderedComponent = (
      <TagName {...component.data} {...params} onChange={handleFunction}>
        {component.children &&
          component.children.map((children, key) => {
            return <div key={key}>{render(children, key)}</div>;
          })}
      </TagName>
    );
    if (component.gridWrap) {
      return (
        <Grid item xs={12} key={componentKey}>
          {renderedComponent}
        </Grid>
      );
    }
    return renderedComponent;
  };

  const handleOnChange = (field: HandleType) => {
    setState((prevState) => ({ ...prevState, [field.name]: field.value }));
  };

  const handleExpand = (field: HandleType) => {
    setAccordionsState((prevState) => ({
      ...prevState,
      [field.name]: field.value,
    }));
  };

  const showMessage = (
    message: string,
    type: 'success' | 'warning' | 'error',
  ) => {
    setInfoMessage(message);
    setInfoType(type);
    setInfoOpened(true);
  };

  const reset = () => {
    setSelectedPipelineVersion(configuration.default);
    setState(defaultState);
    setFilesToUpload([]);
    setSourceLink('');
  };

  const uploadRenderConfigs = async () => {
    let renderData: any[] = [];
    await Promise.all(
      filesToUpload.map(async (fileToUpload) => {
        let fileName = fileToUpload.name;
        fileName = fileName.replaceAll(' ', '_');
        await getUploadUrl(fileName)
          .then(async (uploadUrlData) => {
            await axios
              .put(uploadUrlData.presignedUrl, fileToUpload)
              .then(() => {
                renderData.push({
                  name: fileName,
                  s3_path: uploadUrlData.s3_path,
                });
              })
              .catch((error) => {
                console.error(error);
                showMessage(
                  `Failed to upload ${fileName}. Error: ${error}`,
                  'warning',
                );
              });
          })
          .catch((error) => {
            console.error(error);
            showMessage(
              `Failed to upload ${fileName}. Error: ${error}`,
              'warning',
            );
          });
      }),
    );
    return renderData;
  };

  const registerTask = async () => {
    if (requires.includes('render') && filesToUpload.length === 0) {
      showMessage('Render config not found!', 'warning');
      return;
    }
    if (requires.includes('ml') && sourceLink.length === 0) {
      showMessage('ML source path not found!', 'warning');
      return;
    }

    setIsLoading(true);
    let taskData = new Map();
    if (requires.includes('render')) {
      if (filesToUpload) {
        let renderData = await uploadRenderConfigs();
        if (renderData.length === 0) {
          showMessage('Failed to upload render configs!', 'warning');
          return;
        }
        taskData.set('render_data', renderData);
      }
    }

    if (requires.includes('ml')) {
      let mlData = new Map();
      Object.keys(state).forEach((key: string) => {
        if (!key.includes('Expanded') && key !== 'preset') {
          // @ts-ignore
          mlData.set(key, state[key]);
        }
      });
      mlData.set('gdrive_link', sourceLink);
      mlData.set('pipeline_version', selectedPipelineVersion);
      taskData.set('ml_data', Object.fromEntries(mlData.entries()));
    }

    await ProdPipelineApi.createTasks(
      Object.fromEntries(taskData.entries()),
      isComplexTask,
      stage,
      props.userName,
    )
      .then((response) => {
        if (response.status === 200)
          showMessage(`Tasks created. Ids: ${response.data.ids}`, 'success');
        if (response.data && response.data.statusCode !== 200)
          showMessage(
            'Failed to register task. Error: ' + response.data.error,
            'warning',
          );
      })
      .catch((error) => {
        console.error(error);
        showMessage('Failed to register task. Error: ' + error, 'warning');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getUploadUrl = async (fileName: string) => {
    const response = await ProdPipelineApi.getUploadUrl(fileName, 'renders');
    if (response.status === 200 && response.data) {
      return response.data;
    }
  };

  return (
    <Container>
      <Modal open={configurationLoading || isLoading}>
        <LoadingIcon />
      </Modal>
      <InfoControl
        isOpen={infoOpened}
        message={infoMessage}
        setIsOpen={setInfoOpened}
        type={infoType}
      />
      <Header
        userName={props.userName}
        name={'Register task'}
        isAdmin={props.isAdmin}
      />
      <RegisterContainer>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <SelectControl
              id={'stage'}
              value={stage}
              items={Object.keys(configuration.services)}
              description={'Stage'}
              disabled={false}
              canBeEmpty={false}
              onChange={(field) => {
                setStage(field.value);
                reset();
              }}
            />
          </Grid>
          <Grid item xs={0.5} />
          <Grid item xs={2}>
            <FormControl>
              <FormHelperText>Complex task</FormHelperText>
              <Checkbox
                checked={isComplexTask}
                color="default"
                onChange={() => {
                  setIsComplexTask(!isComplexTask);
                }}
                size={'medium'}
              />
            </FormControl>
          </Grid>
          {requires.includes('render') && (
            <Grid item xs={12}>
              <AccordionControl
                id={'renderInputs'}
                expanded={isRenderInputsExtended}
                name={'Render inputs'}
                description={''}
                onChange={() => {
                  setIsRenderInputsExtended(!isRenderInputsExtended);
                }}
              >
                <FileSelectWrapper>
                  <UploadFile
                    setFilesToUpload={setFilesToUpload}
                    accept={{
                      'application/json': ['.json'],
                    }}
                    multiple={true}
                  />
                </FileSelectWrapper>
                {filesToUpload &&
                  filesToUpload.map((fileToUpload, key) => {
                    return (
                      <Grid
                        container
                        spacing={1}
                        sx={{ alignItems: 'center' }}
                        key={key}
                      >
                        <Grid item xs={0.5} />
                        <Grid item xs={11}>
                          <Typography>{fileToUpload.name}</Typography>
                        </Grid>
                        <Grid item xs={0.5}>
                          <IconButton
                            onClick={() => {
                              setFilesToUpload(
                                filesToUpload.filter((e, i) => i !== key),
                              );
                            }}
                          >
                            <DeleteIcon fontSize={'medium'} />
                          </IconButton>
                        </Grid>
                      </Grid>
                    );
                  })}
              </AccordionControl>
            </Grid>
          )}
          {requires.includes('ml') && (
            <Grid item xs={12}>
              <AccordionControl
                id={'mlInputs'}
                expanded={isMlInputsExtended}
                name={'ML inputs'}
                description={''}
                onChange={() => {
                  setIsMlInputsExtended(!isMlInputsExtended);
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <SelectControl
                      id={'pipelineVersion'}
                      value={selectedPipelineVersion}
                      items={configuration.pipeline_versions}
                      description={'Pipeline version'}
                      disabled={false}
                      canBeEmpty={false}
                      onChange={(field) => {
                        setSelectedPipelineVersion(field.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldControl
                      value={sourceLink}
                      onChange={(field) => {
                        setSourceLink(field.value);
                      }}
                      id={'gdrive_link'}
                      description={'Gdrive link'}
                    />
                  </Grid>
                  {pipelineVersionLoading ? (
                    <LoadingIcon />
                  ) : (
                    structure.map((component, key) => {
                      return render(component, key);
                    })
                  )}
                </Grid>
              </AccordionControl>
            </Grid>
          )}
          <Grid item xs={12}>
            <BottomNavigation showLabels>
              <BottomNavigationAction
                label="Reset"
                icon={<RotateLeftIcon />}
                onClick={reset}
              />
              <BottomNavigationAction
                label="Create"
                icon={<AddIcon />}
                onClick={registerTask}
              />
            </BottomNavigation>
          </Grid>
        </Grid>
      </RegisterContainer>
    </Container>
  );
};

const Container = styled.div`
  margin: auto;
  max-width: 1440px;
`;

const RegisterContainer = styled.div`
  padding: 1%;

  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-radius: 10px;
`;

const FileSelectWrapper = styled.div`
  padding: 1%;
  margin-bottom: 1%;
  border: 1px solid grey;
  display: block;
  border-radius: 10px;
  text-align: center;
`;

export default CreatePipelineTask;
