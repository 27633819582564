import createApiClient from './ApiClient';
import { AxiosResponse } from 'axios';
import { GetRunsResponseDto } from './types/GetRunsResponseDto';
import { GetConfigurationResponseDto } from './types/GetConfigurationResponseDto';
import { GetPipelineConfigurationResponseDto } from './types/GetPipelineConfigurationResponseDto';
import { GetPresetConfigurationResponseDto } from './types/GetPresetConfigurationResponseDto';
import { GetRendersResponseDto } from './types/GetRendersResponseDto';
import { GetTasksResponseDto } from './types/GetTasksResponseDto';
import { GetEntitiesResponseDto } from './types/GetEntitiesResponseDto';

const routes = {
  getUploadUrl: () => 'url/prodPipelineGetUploadUrl',

  getRuns: () => `/grs/prodPipelineGetRuns`,
  getRun: () => `/run/prodPipelineGetRun`,
  deleteRuns: () => '/dr/prodPipelineDeleteRuns',
  restartRuns: () => '/rr/prodPipelineRestartRuns',
  scheduleRun: () => '/sr/prodPipelineScheduleRun',

  getConfiguration: () => '/gc/prodPipelineGetConfiguration',
  getPipelineConfiguration: () => '/gp/prodPipelineGetPipelineConfiguration',

  getPreset: () => '/gpr/prodPipelineGetPreset',
  savePreset: () => '/sp/prodPipelineSavePreset',
  removePreset: () => '/rp/prodPipelineRemovePreset',

  getRenders: () => `/gre/prodPipelineGetRenders`,
  scheduleRender: () => '/sre/prodPipelineScheduleRender',
  deleteRender: () => '/dre/prodPipelineDeleteRender',
  getRenderLogs: () => `/grl/prodPipelineGetRenderLogs`,

  createTasks: () => '/ct/prodPipelineCreateTasks',
  getTasks: () => `/gt/prodPipelineGetTasks`,
  getEntities: () => `/ge/prodPipelineGetEntities`,
  createEntity: () => '/ce/prodPipelineCreateEntity',
  deleteEntity: () => '/de/prodPipelineDeleteEntities',
  editeEntity: () => '/ee/prodPipelineEditEntity',
};

export default class ProdPipelineApi {
  private static client = createApiClient();

  public static getUploadUrl(
    fileName: string | null = null,
    type: string | null = null,
  ) {
    let request = {
      file_name: fileName,
      type: type,
    };
    return this.client.post(routes.getUploadUrl(), request);
  }

  public static getRuns(
    lastKey: any | null,
  ): Promise<AxiosResponse<GetRunsResponseDto>> {
    const request = {
      start_from: lastKey,
    };
    return this.client.post(routes.getRuns(), request);
  }

  public static getRun(runId: string) {
    const request = {
      run_id: runId,
    };
    return this.client.post(routes.getRun(), request);
  }

  public static deleteRuns(runIds: string[]) {
    const request = {
      run_ids: runIds,
    };
    return this.client.post(routes.deleteRuns(), request);
  }

  public static restartRuns(runIds: string[], userName: string) {
    const request = {
      run_ids: runIds,
      created_by: userName,
    };
    return this.client.post(routes.restartRuns(), request);
  }

  public static scheduleRun(
    runData: any,
    pipelineVersion: string,
    userName: string,
  ) {
    const request = {
      run_data: {
        ...runData,
        pipeline_version: pipelineVersion,
        created_by: userName,
      },
    };
    return this.client.post(routes.scheduleRun(), request);
  }

  public static getConfiguration(): Promise<
    AxiosResponse<GetConfigurationResponseDto>
  > {
    return this.client.get(routes.getConfiguration());
  }

  public static getPipelineConfiguration(
    version: string,
  ): Promise<AxiosResponse<GetPipelineConfigurationResponseDto>> {
    const request = {
      version: version,
    };
    return this.client.post(routes.getPipelineConfiguration(), request);
  }

  public static getPreset(
    name: string,
  ): Promise<AxiosResponse<GetPresetConfigurationResponseDto>> {
    const request = {
      name: name,
    };
    return this.client.post(routes.getPreset(), request);
  }

  public static savePreset(name: string, data: {}, pipelineVersion: string) {
    const request = {
      name: name,
      data: data,
      pipeline_version: pipelineVersion,
    };
    return this.client.post(routes.savePreset(), request);
  }

  public static removePreset(presetName: string) {
    const request = {
      preset_name: presetName,
    };
    return this.client.post(routes.removePreset(), request);
  }

  public static getRenders(): Promise<AxiosResponse<GetRendersResponseDto>> {
    return this.client.get(routes.getRenders());
  }

  public static getRenderLogs(renderId: string) {
    let request = {
      render_id: renderId,
    };
    return this.client.post(routes.getRenderLogs(), request);
  }

  public static async scheduleRender(
    userName: string,
    renderId: string,
    s3Path: string,
    fileName: string,
    priority: boolean,
  ) {
    const request = {
      created_by: userName,
      render_id: renderId,
      s3_path: s3Path,
      name: fileName,
      priority: priority,
    };
    return this.client.post(routes.scheduleRender(), request);
  }

  public static deleteRender(renderIds: string[]) {
    const request = {
      render_ids: renderIds,
    };
    return this.client.post(routes.deleteRender(), request);
  }

  public static async createTasks(
    taskData: Object,
    isComplexTask: boolean,
    stage: string,
    userName: string,
  ) {
    const request = {
      ...taskData,
      ...{
        is_complex: isComplexTask,
        stage: stage,
        created_by: userName,
      },
    };
    return this.client.post(routes.createTasks(), request);
  }

  public static getTasks(
    lastKey: any | null,
  ): Promise<AxiosResponse<GetTasksResponseDto>> {
    const request = {
      start_from: lastKey,
    };
    return this.client.post(routes.getTasks(), request);
  }


  public static getEntities(entityType: string): Promise<AxiosResponse<GetEntitiesResponseDto>> {
    const request = {
      entity_type: entityType,
    };
    return this.client.post(routes.getEntities(), request)
  }


  public static createEntity(
    entityType: string,
    data: any,
    userName: string,
  ) {
    const request = {
      entity_type: entityType,
      created_by: userName,
      entity: data,
    };
    return this.client.post(routes.createEntity(), request)
  }


  public static editeEntity(
    entityType: string,
    data: any,
    userName: string,
  ) {
    const request = {
      entity_type: entityType,
      entity_data: data,
      modified_dy: userName,
    };
    return this.client.post(routes.editeEntity(), request)
  }


  public static deleteEntity(
    entityType: string,
    entitiesIds: string[],
  ) {
    const request = {
      entity_type: entityType,
      entity_ids: entitiesIds,
    };
    return this.client.post(routes.deleteEntity(), request)
  }

}