import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Header from '../Header';
import axios from 'axios';
import ProdPipelineApi from '../../api/ProdPipelineApi';
import { RenderDto } from '../../api/types/GetRendersResponseDto';
import { UploadFile } from '../Controls/SelectFiles';
import { IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import LoadingIcon from '../Controls/LoadingIcon';
import LogsModal from '../Modals/Logs';
import CheckIcon from '@mui/icons-material/Check';
import RenderTable from '../Tables/RenderTable';
import CheckBoxControl from '../Controls/CheckBoxControl';

interface RendersProps {
  userName: string;
  isAdmin: boolean;
}

const Renders = (props: RendersProps) => {
  const [renders, setRenders] = useState<Map<string, RenderDto>>(new Map());
  const [priority, setPriority] = useState(true);
  const [filesToUpload, setFilesToUpload] = useState<File[] | null>(null);
  const [filesUploading, setFilesUploading] = useState(false);
  const [rendersLoading, setRendersLoading] = useState(false);
  const [logs, setLogs] = useState('');
  const [isLogsOpen, setIsLogsOpen] = useState(false);
  const [isLogsLoading, setIsLogsLoading] = useState(false);

  const loadRenders = async () => {
    setRendersLoading(true);
      const rendersResponse = await ProdPipelineApi.getRenders();
      if (rendersResponse.data) {
        const responseRenders = rendersResponse.data.renders;
        if (responseRenders) {
          let tempRenders: RenderDto[] = [];
          for (let key in responseRenders) {
            tempRenders.push(...responseRenders[key]);
          }
          setRenders(new Map(tempRenders.map((obj) => [obj.render_id, obj])));
        }
      }
      setRendersLoading(false);
    };

  useEffect(() => {
    loadRenders();
    // eslint-disable-next-line
  }, []);

  const resetFileToUpload = () => {
    setFilesToUpload(null);
    setFilesUploading(false);
  };

  const processRender = () => {
    if (filesToUpload && filesToUpload.length > 0) {
      filesToUpload.forEach((fileToUpload) => {
        let fileName = fileToUpload.name.replace(' ', '_');
        setFilesUploading(true);
        getUploadUrl(fileName).then((uploadUrlData) => {
          axios
            .put(uploadUrlData.presignedUrl, fileToUpload)
            .then(() => {
              ProdPipelineApi.scheduleRender(
                props.userName,
                uploadUrlData.id,
                uploadUrlData.s3_path,
                fileName,
                priority,
              ).then(() => {
                resetFileToUpload();
                loadRenders();
              });
            })
            .catch((error) => {
              console.error(error);
              resetFileToUpload();
            });
        });
        setPriority(true);
      });
    }
  };

  const getUploadUrl = async (fileName: string) => {
    const response = await ProdPipelineApi.getUploadUrl(fileName, 'renders');
    if (response.status === 200 && response.data) {
      return response.data;
    }
  };

  const deleteRender = async (renderIds: string[]) => {
    setRendersLoading(true);
    await ProdPipelineApi.deleteRender(renderIds);
    loadRenders();
  };

  const loadLogs = async (renderId: string) => {
    setIsLogsOpen(true);
    setIsLogsLoading(true);
    ProdPipelineApi.getRenderLogs(renderId)
      .then((renderLogs) => {
        setLogs(renderLogs.data.logs);
      })
      .catch(() => {
        setLogs('Failed to load logs. Try again later');
      })
      .finally(() => {
        setIsLogsLoading(false);
      });
  };

  return (
    <Container>
      <LogsModal
        isLoading={isLogsLoading}
        isOpen={isLogsOpen}
        closeModal={() => {
          setIsLogsOpen(false);
        }}
        logs={logs}
      />
      <Header
        userName={props.userName}
        name={'Renders'}
        isAdmin={props.isAdmin}
      />
      <RendersContainer>
        <FileSelectWrapper>
          <UploadFile
            setFilesToUpload={setFilesToUpload}
            accept={{
              'application/json': ['.json'],
            }}
            multiple={true}
          />
        </FileSelectWrapper>
        {filesToUpload && filesToUpload.length > 0 && (
          <PreviewWrapper>
            {filesUploading ? (
              <LoadingIcon size={'small'} />
            ) : (
              <ZipContainer>
                <ZipToUploadPreview>
                  <Typography style={{ width: '30%' }} />
                  <JobsToUploadButtons>
                    <CheckBoxControl
                      description={'Priority'}
                      id={'priority'}
                      checked={priority}
                      onChange={(data) => {
                        setPriority(data.value);
                      }}
                    />
                    <IconButton onClick={processRender}>
                      <CheckIcon fontSize={'large'} />
                    </IconButton>
                  </JobsToUploadButtons>
                </ZipToUploadPreview>
                {filesToUpload.map((fileToUpload, key) => {
                  return (
                    <ZipToUploadPreview key={key}>
                      <Typography style={{ width: '30%' }}>
                        {fileToUpload.name}
                      </Typography>
                      <JobToUploadButtons>
                        <IconButton
                          onClick={() => {
                            setFilesToUpload(
                              filesToUpload.filter((e, i) => i !== key),
                            );
                          }}
                        >
                          <DeleteIcon fontSize={'medium'} />
                        </IconButton>
                      </JobToUploadButtons>
                    </ZipToUploadPreview>
                  );
                })}
              </ZipContainer>
            )}
          </PreviewWrapper>
        )}
        <RenderTable
          data={Array.from(renders.values())}
          reloadData={loadRenders}
          loading={rendersLoading}
          loadLogs={loadLogs}
          deleteRenders={deleteRender}
        />
      </RendersContainer>
    </Container>
  );
};

const Container = styled.div`
  margin: auto;
  max-width: 1440px;
`;

const RendersContainer = styled.div`
  padding: 1%;

  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-radius: 10px;
`;

const FileSelectWrapper = styled.div`
  padding: 1%;
  margin-bottom: 1%;
  border: 1px solid black;
  border-radius: 10px;
  text-align: center;
`;

const PreviewWrapper = styled.div`
  padding: 1%;
  margin-bottom: 1%;
  border: 1px solid black;
  border-radius: 10px;
`;

const ZipContainer = styled.div`
  align-items: center;
`;

const ZipToUploadPreview = styled.div`
  height: 38px;
  display: flex;
  align-items: center;
`;

const JobsToUploadButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 2%;
  margin-left: 1%;
  gap: 5px;
  padding-left: 57%;
`;

const JobToUploadButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 2%;
  margin-left: 1%;
  gap: 5px;
  padding-left: 66%;
`;

export default Renders;
