import { useEffect, useMemo, useState } from 'react';
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  MRT_PaginationState,
  useMaterialReactTable,
} from 'material-react-table';
import { RenderDto } from '../../../api/types/GetRendersResponseDto';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { IconButton, Tooltip } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

interface RenderTableProps {
  data: RenderDto[];
  reloadData: () => void;
  loadLogs: (renderId: string) => void;
  deleteRenders: (renderIds: string[]) => void;
  loading: boolean;
}

const RenderTable = (props: RenderTableProps) => {
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
  const data = useMemo(() => props.data, [props.data]);
  const isLoading = useMemo(() => props.loading, [props.loading]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });

  useEffect(() => {
    setRowSelection({});
  }, [props.data]);

  const columns = useMemo<MRT_ColumnDef<RenderDto>[]>(
    () => [
      {
        accessorKey: 'render_id', //access nested data with dot notation
        header: 'ID',
        id: 'renderId',
        size: 250,
      },
      {
        accessorKey: 'render_name', //access nested data with dot notation
        header: 'Name',
        size: 300,
      },
      {
        accessorKey: 'created_at',
        size: 220,
        accessorFn: (originalRow) => new Date(originalRow.created_at),
        header: 'Created at',
        filterVariant: 'date',
        filterFn: 'greaterThan',
        enableGlobalFilter: false,
        Cell: ({ cell }) => new Date(cell.getValue<Date>()).toLocaleString(),
      },
      {
        accessorKey: 'created_by', //normal accessorKey
        header: 'Created by',
        size: 250,
      },
      {
        accessorKey: 'state',
        header: 'State',
        size: 200,
        filterVariant: 'multi-select',
        filterSelectOptions: ['created', 'processing', 'finished', 'failed'],
        Cell: ({ cell, row }) => (
          <Box>
            {row.original.state === 'failed' ||
            row.original.state === 'finished' ? (
              <Link
                component="button"
                style={
                  row.original.state === 'failed'
                    ? { color: 'red' }
                    : { color: 'black' }
                }
                onClick={() => {
                  props.loadLogs(row.original.render_id);
                }}
              >
                {cell.getValue<string>()}
              </Link>
            ) : (
              cell.getValue<string>()
            )}
          </Box>
        ),
      },
      {
        accessorKey: 'started_at',
        size: 220,
        accessorFn: (originalRow) => new Date(originalRow.started_at),
        header: 'Started at',
        filterVariant: 'date',
        filterFn: 'greaterThan',
        enableGlobalFilter: false,
        Cell: ({ cell }) => new Date(cell.getValue<Date>()).toLocaleString(),
      },
      {
        accessorKey: 'finished_at',
        size: 220,
        accessorFn: (originalRow) => new Date(originalRow.finished_at),
        header: 'Finished at',
        filterVariant: 'date',
        filterFn: 'greaterThan',
        enableGlobalFilter: false,
        Cell: ({ cell }) => new Date(cell.getValue<Date>()).toLocaleString(),
      },
      {
        accessorKey: 'result_link',
        header: 'Result',
        size: 250,
        enableColumnFilter: false,
        Cell: ({ cell, row }) => (
          <Box>
            {row.getValue<string>('state') === 'finished' && (
              <Link
                href={cell.getValue<string>()}
                style={{ cursor: 'pointer', color: 'black' }}
              >
                download results
              </Link>
            )}
          </Box>
        ),
        enableGlobalFilter: false,
      },
    ],
    // eslint-disable-next-line
    [],
  );
  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: (row) => row.original.state !== 'processing',
    onRowSelectionChange: setRowSelection,
    getRowId: (row) => row.render_id,
    positionToolbarAlertBanner: 'bottom',
    renderTopToolbarCustomActions: () => (
      <div>
        <Tooltip arrow title="Refresh Data">
          <IconButton onClick={() => props.reloadData()}>
            <CachedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="Delete rows">
          <span>
            <IconButton
              onClick={() => props.deleteRenders(Object.keys(rowSelection))}
              disabled={Object.keys(rowSelection).length === 0}
            >
              <DeleteIcon />
            </IconButton>
          </span>
        </Tooltip>
      </div>
    ),
    onPaginationChange: setPagination,
    state: { isLoading, rowSelection, pagination },
    enableColumnResizing: true,
    initialState: {
      columnVisibility: {
        renderId: false,
        started_at: false,
        finished_at: false,
      },
      density: 'compact',
    },
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MaterialReactTable table={table} />
    </LocalizationProvider>
  );
};

export default RenderTable;
