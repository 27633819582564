import React, { useEffect, useState } from 'react';
import './App.css';
import { COGNITO } from './configs';
import { Amplify, Auth } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import LoadingIcon from './components/Controls/LoadingIcon';
import styled from 'styled-components';
import { ConfigurationProvider } from './context/ConfigurationContext';
import ProdPipeline from './components/ProdPipeline';
import { RunsProvider } from './context/RunsContext';

Amplify.configure({
  aws_cognito_region: COGNITO.REGION,
  aws_user_pools_id: COGNITO.USER_POOL_ID,
  aws_user_pools_web_client_id: COGNITO.APP_CLIENT_ID,
});

function App() {
  const [loading, setLoading] = useState(true);
  const [userName, setUserName] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((userData) => {
      if (userData) {
        if (
          userData.signInUserSession.accessToken.payload[
            'cognito:groups'
          ].includes('admin')
        ) {
          setIsAdmin(true);
        }
        setUserName(userData.username);
        setLoading(false);
      }
    });
  }, []);

  if (loading) {
    return (
      <LoadingContainer>
        <LoadingIcon />
      </LoadingContainer>
    );
  } else
    return (
      <ConfigurationProvider>
        <RunsProvider>
          <ProdPipeline userName={userName} isAdmin={isAdmin} />
        </RunsProvider>
      </ConfigurationProvider>
    );
}

const LoadingContainer = styled.div`
  min-height: 775px;
  display: grid;
`;

export default withAuthenticator(App);
