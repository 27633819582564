import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import styled from 'styled-components';

interface ColorPickerControlProps {
  headColor: number[] | null;
  disabled: boolean;
  setSkinColor: { (skinColor: number[]): void };
}

const ColorPickerControl = (props: ColorPickerControlProps) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  return (
    <Container disabled={props.disabled}>
      <Swatch
        onClick={() => {
          setDisplayColorPicker(!displayColorPicker);
        }}
      >
        <Color
          color={
            props.headColor
              ? props.headColor[0] +
                ', ' +
                props.headColor[1] +
                ', ' +
                props.headColor[2]
              : ''
          }
        />
      </Swatch>
      {displayColorPicker && (
        <Popover>
          <Cover
            onClick={() => {
              setDisplayColorPicker(false);
            }}
          />
          <SketchPicker
            color={
              props.headColor
                ? {
                    r: props.headColor[0],
                    g: props.headColor[1],
                    b: props.headColor[2],
                  }
                : ''
            }
            onChange={(color) => {
              props.setSkinColor([color.rgb.r, color.rgb.g, color.rgb.b]);
            }}
          />
        </Popover>
      )}
    </Container>
  );
};

const Container = styled.div<{ disabled: boolean }>`
  ${(props) => (props.disabled ? 'pointer-events: none; opacity: 0.4;' : '')};
`;

const Swatch = styled.div`
  background: #fff;
  border: none;
  cursor: pointer;
`;

const Color = styled.div<{ color: string }>`
  width: 28px;
  height: 28px;
  border-radius: 4px;
  background: rgb(${(props) => props.color});
`;

const Popover = styled.div`
  position: absolute;
  z-index: 2;
`;

const Cover = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export default ColorPickerControl;
