import { FormControl, FormHelperText, MenuItem, Select } from '@mui/material';
import React, { Children } from 'react';
import styled from 'styled-components';
import { HandleType } from '../../../configs';

interface SelectControlProps {
  items: string[];
  id: string;
  value: string;
  description: string;
  disabled?: boolean;
  onChange: { (field: HandleType): void };
  children?: React.ReactNode;
  conditions?: string[];
  canBeEmpty: boolean;
}

const SelectControl = (props: SelectControlProps) => {
  const handleChange = (event: any) => {
    let field = {
      name: props.id,
      value: event.target.value,
    };
    props.onChange(field);
  };
  return (
    <Container>
      <FormControl
        id={props.id}
        disabled={props.disabled}
        sx={{
          minWidth: 400, // input label when focused
          '& label.Mui-focused': {
            color: 'black',
          },
          // focused color for input with variant='standard'
          '& .MuiInput-underline:after': {
            borderBottomColor: 'black',
          },
          // focused color for input with variant='filled'
          '& .MuiFilledInput-underline:after': {
            borderBottomColor: 'black',
          },
          // focused color for input with variant='outlined'
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: 'black',
            },
          },
        }}
      >
        <FormHelperText>{props.description}</FormHelperText>
        <Select
          size={'small'}
          value={props.value}
          displayEmpty={props.canBeEmpty}
          inputProps={{ 'aria-label': 'Without label' }}
          onChange={handleChange}
        >
          {props.canBeEmpty && (
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
          )}
          {props.items.map((item, key) => {
            return (
              <MenuItem key={key} value={item}>
                {item}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {props.conditions?.map((value, index) => {
        if (props.value === value && props.children) {
          return (
            <div key={index}>{Children.toArray(props.children)[index]}</div>
          );
        } else {
          return <div key={index}></div>;
        }
      })}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
`;

export default SelectControl;
