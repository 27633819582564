import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from 'styled-components';
import { HandleType } from '../../../configs';

interface AccordionControlProps {
  id: string;
  expanded: boolean;
  name: string;
  description: string;
  children: React.ReactNode;
  onChange: { (field: HandleType): void };
}

const AccordionControl = (props: AccordionControlProps) => {
  const handleChange = () => () => {
    let field = {
      name: props.id,
      value: !props.expanded,
    };
    props.onChange(field);
  };
  return (
    <Container>
      <Accordion
        id={props.id}
        expanded={props.expanded}
        onChange={handleChange()}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
            {props.name}
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            {props.description}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AccordionChildes>{props.children}</AccordionChildes>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

const Container = styled.div`
  padding-bottom: 5px;
`;

const AccordionChildes = styled.div`
  display: block;
`;

export default AccordionControl;
