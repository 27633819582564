import React from 'react';
import Modal from '@mui/material/Modal';
import styled from 'styled-components';
import LoadingIcon from '../../Controls/LoadingIcon';

interface LogsProps {
  isOpen: boolean;
  isLoading: boolean;
  logs: string;
  closeModal: { (): void };
}

const LogsModal = (props: LogsProps) => {
  return (
    <ModalContainer open={props.isOpen} onClose={props.closeModal}>
      {props.isLoading ? (
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <LoadingIcon size={'small'} />
        </div>
      ) : (
        <OutputTextArea
          readOnly
          onClick={(e) => {
            e.stopPropagation();
          }}
          defaultValue={props.logs}
        />
      )}
    </ModalContainer>
  );
};

const ModalContainer = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OutputTextArea = styled.textarea`
  width: 85%;
  height: 85%;
  padding: 20px;
`;

export default LogsModal;
