import { FormControl, FormHelperText } from '@mui/material';
import React from 'react';
import CreatableSelect from 'react-select/creatable';

interface PresetControlProps {
  items: string[];
  selectedPreset: string | null;
  setSelectedPreset: { (option: string | null): void };
  disabled: boolean;
}

const PresetControl = (props: PresetControlProps) => {
  const handleChange = (newValue: any) => {
    if (newValue && newValue.value) {
      props.setSelectedPreset(newValue.value);
    } else props.setSelectedPreset(null);
  };

  const handleCreate = (inputValue: string) => {
    props.setSelectedPreset(inputValue.replace(/\W/g, '_'));
  };

  return (
    <FormControl sx={{ width: '100%' }}>
      <FormHelperText>Preset</FormHelperText>
      <CreatableSelect
        isDisabled={props.disabled}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: 'rgb(242, 242, 242)',
            primary75: 'rgb(222, 235, 255)',
            primary: 'black',
          },
        })}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            height: '40px',
            minWidth: 400,
          }),
          option: (styles, { isDisabled, isFocused, isSelected }) => ({
            ...styles,
            backgroundColor: isDisabled
              ? undefined
              : isSelected
              ? 'rgba(25, 118, 210, 0.08)'
              : isFocused
              ? 'rgb(242, 242, 242)'
              : undefined,
            color: 'black',
            ':active': {
              ...styles[':active'],
              backgroundColor: !isDisabled
                ? isSelected
                  ? 'black'
                  : 'rgb(179, 179, 179)'
                : undefined,
            },
          }),
        }}
        isClearable
        onChange={handleChange}
        onCreateOption={handleCreate}
        options={props.items.map((s) => ({ label: s, value: s }))}
        value={
          props.selectedPreset
            ? { label: props.selectedPreset, value: props.selectedPreset }
            : null
        }
      />
    </FormControl>
  );
};

export default PresetControl;
