import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Header from '../Header';
import ProdPipelineApi from '../../api/ProdPipelineApi';
import { TaskDto } from '../../api/types/GetTasksResponseDto';
import TasksTable from '../Tables/TasksTable';

interface TasksProps {
  userName: string;
  isAdmin: boolean;
}

const Tasks = (props: TasksProps) => {
  const [tasks, setTasks] = useState<Map<string, TaskDto>>(new Map());
  const [tasksLoading, setTasksLoading] = useState(false);

  const loadTasks = () => {
    (async () => {
      setTasksLoading(true);
      const tasksResponse = await ProdPipelineApi.getTasks(null);
      if (tasksResponse.data) {
        const responseTasks = tasksResponse.data.tasks;
        if (responseTasks) {
          let tmpTasks: Map<string, TaskDto> = new Map();
          responseTasks.forEach((task) => {
            tmpTasks.set(task.id, task);
          });
          setTasks(tmpTasks);
        }
      }
      setTasksLoading(false);
    })();
  };

  useEffect(() => {
    loadTasks();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <Header
        userName={props.userName}
        name={'Tasks'}
        isAdmin={props.isAdmin}
      />
      <TasksContainer>
        <TasksTable
          data={Array.from(tasks.values())}
          reloadData={loadTasks}
          loading={tasksLoading}
        />
      </TasksContainer>
    </Container>
  );
};

const Container = styled.div`
  margin: auto;
  max-width: 1440px;
`;

const TasksContainer = styled.div`
  padding: 1%;

  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-radius: 10px;
`;

export default Tasks;
