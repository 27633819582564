import type { MRT_ColumnDef } from 'material-react-table';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import React from 'react';
import { EntityType } from '../../../configs';

export const getColumnsByType = (entityType: EntityType) => {
  if (entityType === EntityType.BODY_IDENTITY) return bodyIdentityColumns;
  if (entityType === EntityType.HEAD) return headIdentityColumns;
  return [];
};

const validateNewEntity = (value: string) => !!value?.length;

export function validateEntity(data: any, entityType: EntityType) {
  if (entityType === EntityType.BODY_IDENTITY)
    return {
      name: !validateNewEntity(data.name) ? 'Name is Required' : '',
      config: !validateNewEntity(data.config) ? 'Config is Required' : '',
      preview: !validateNewEntity(data.preview) ? 'Preview is Required' : '',
    };
  if (entityType === EntityType.HEAD)
    return {
      name: !validateNewEntity(data.name) ? 'Name is Required' : '',
      config: !validateNewEntity(data.config) ? 'Config is Required' : '',
    };
  return {};
}

export const bodyIdentityColumns: MRT_ColumnDef<any>[] = [
  {
    accessorKey: 'id', //access nested data with dot notation
    header: 'ID',
    size: 250,
    enableEditing: false,
  },
  {
    accessorKey: 'name', //access nested data with dot notation
    header: 'Name',
    size: 300,
    muiEditTextFieldProps: {
      required: true,
    },
  },
  {
    accessorKey: 'type', //access nested data with dot notation
    header: 'Type',
    size: 300,
    enableEditing: false,
  },
  {
    accessorKey: 'config',
    header: 'Config',
    size: 250,
    muiEditTextFieldProps: {
      required: true,
    },
    enableColumnFilter: false,
    Cell: ({ cell }) => (
      <Box>
        <Link
          href={cell.getValue<string>()}
          style={{ cursor: 'pointer', color: 'black' }}
        >
          {cell.getValue<string>()}
        </Link>
      </Box>
    ),
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'preview',
    header: 'Preview',
    size: 250,
    muiEditTextFieldProps: {
      required: true,
    },
    enableColumnFilter: false,
    Cell: ({ cell }) => (
      <Box>
        <Link
          href={cell.getValue<string>()}
          style={{ cursor: 'pointer', color: 'black' }}
        >
          {cell.getValue<string>()}
        </Link>
      </Box>
    ),
    enableGlobalFilter: false,
  },
];

export const headIdentityColumns: MRT_ColumnDef<any>[] = [
  {
    accessorKey: 'id', //access nested data with dot notation
    header: 'ID',
    size: 250,
    enableEditing: false,
  },
  {
    accessorKey: 'name', //access nested data with dot notation
    header: 'Name',
    size: 300,
    muiEditTextFieldProps: {
      required: true,
    },
  },
  {
    accessorKey: 'type', //access nested data with dot notation
    header: 'Type',
    size: 300,
    enableEditing: false,
  },
  {
    accessorKey: 'config',
    header: 'Config',
    size: 250,
    muiEditTextFieldProps: {
      required: true,
    },
    enableColumnFilter: false,
    Cell: ({ cell }) => (
      <Box>
        <Link
          href={cell.getValue<string>()}
          style={{ cursor: 'pointer', color: 'black' }}
        >
          {cell.getValue<string>()}
        </Link>
      </Box>
    ),
    enableGlobalFilter: false,
  },
];
