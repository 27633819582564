import { FormControl, FormHelperText, TextField } from '@mui/material';
import React from 'react';
import { HandleType } from '../../../configs';

interface TextFieldControlProps {
  description: string;
  value: string;
  id: string;
  onChange: { (field: HandleType): void };
}

const TextFieldControl = (props: TextFieldControlProps) => {
  const handleChange = (event: any) => {
    let field = {
      name: props.id,
      value: event.target.value,
    };
    props.onChange(field);
  };
  return (
    <FormControl sx={{ width: '100%' }}>
      <FormHelperText>{props.description}</FormHelperText>
      <TextField
        id={props.id}
        sx={{
          minWidth: 1000,
          width: '100%', // input label when focused
          '& label.Mui-focused': {
            color: 'black',
          },
          // focused color for input with variant='standard'
          '& .MuiInput-underline:after': {
            borderBottomColor: 'black',
          },
          // focused color for input with variant='filled'
          '& .MuiFilledInput-underline:after': {
            borderBottomColor: 'black',
          },
          // focused color for input with variant='outlined'
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: 'black',
            },
          },
        }}
        size="small"
        // helperText={props.description}
        variant="outlined"
        multiline
        onChange={handleChange}
        value={props.value}
      />
    </FormControl>
  );
};

export default TextFieldControl;
