import React, { useEffect, useMemo, useState } from 'react';
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  MRT_PaginationState,
  useMaterialReactTable,
} from 'material-react-table';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { IconButton, Tooltip } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import { TaskDto } from '../../../api/types/GetTasksResponseDto';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { ContentCopy } from '@mui/icons-material';

interface TasksTableProps {
  data: TaskDto[];
  reloadData: () => void;
  loading: boolean;
}

const TasksTable = (props: TasksTableProps) => {
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
  const data = useMemo(() => props.data, [props.data]);
  const isLoading = useMemo(() => props.loading, [props.loading]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });

  useEffect(() => {
    setRowSelection({});
  }, [props.data]);

  const columns = useMemo<MRT_ColumnDef<TaskDto>[]>(
    () => [
      {
        accessorKey: 'id', //access nested data with dot notation
        header: 'ID',
        size: 280,
      },
      {
        accessorKey: 'created_at',
        size: 220,
        accessorFn: (originalRow) => new Date(originalRow.created_at),
        header: 'Created at',
        filterVariant: 'date',
        filterFn: 'equals',
        muiFilterDatePickerProps: {
          format: 'DD/MM/YYYY',
        },
        enableGlobalFilter: false,
        Cell: ({ cell }) => new Date(cell.getValue<Date>()).toLocaleString(),
      },
      {
        accessorKey: 'created_by', //normal accessorKey
        header: 'Created by',
        size: 180,
      },
      {
        accessorKey: 'state',
        header: 'State',
        size: 170,
        filterVariant: 'multi-select',
        filterSelectOptions: ['created', 'processing', 'finished', 'failed'],
      },
      {
        accessorKey: 'render_config', //normal accessorKey
        header: 'Render config',
        size: 200,
        enableClickToCopy: true,
        Cell: ({ cell }) => (
          <Box
            title={cell.getValue<string>()}
            sx={{
              textWrap: 'nowrap',
              width: '180px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {cell.getValue<string>()}
          </Box>
        ),
        enableGlobalFilter: false,
      },
      {
        accessorKey: 'render_id', //access nested data with dot notation
        header: 'Render ID',
        size: 280,
      },
      {
        accessorKey: 'render_results',
        header: 'Render result',
        size: 150,
        enableColumnFilter: false,
        Cell: ({ cell }) => (
          <Box>
            {cell.getValue<string>() && (
              <Link
                href={cell.getValue<string>()}
                style={{ cursor: 'pointer', color: 'black' }}
              >
                Download results
              </Link>
            )}
          </Box>
        ),
        enableGlobalFilter: false,
      },
      {
        accessorKey: 'ml_config', //normal accessorKey
        header: 'ML config',
        size: 200,
        enableClickToCopy: true,
        Cell: ({ cell }) => (
          <Box
            title={cell.getValue<string>()}
            sx={{
              textWrap: 'nowrap',
              width: '180px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {cell.getValue<string>()}
          </Box>
        ),
        enableGlobalFilter: false,
      },
      {
        accessorKey: 'ml_id', //access nested data with dot notation
        header: 'ML ID',
        size: 280,
      },
      {
        accessorKey: 'ml_results',
        header: 'ML result',
        size: 120,
        enableColumnFilter: false,
        Cell: ({ cell }) => (
          <Box>
            {cell.getValue<string>() && (
              <Link
                href={cell.getValue<string>()}
                style={{ cursor: 'pointer', color: 'black' }}
                target="_blank"
              >
                Browse
              </Link>
            )}
          </Box>
        ),
        enableClickToCopy: true,
        muiCopyButtonProps: {
          fullWidth: true,
          startIcon: <ContentCopy />,
          sx: { justifyContent: 'flex-start' },
        },
        enableGlobalFilter: false,
      },
    ],
    // eslint-disable-next-line
    [],
  );
  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: (row) => row.original.state !== 'processing',
    onRowSelectionChange: setRowSelection,
    getRowId: (row) => row.id,
    positionToolbarAlertBanner: 'bottom',
    renderTopToolbarCustomActions: () => (
      <div>
        <Tooltip arrow title="Refresh Data">
          <IconButton onClick={() => props.reloadData()}>
            <CachedIcon />
          </IconButton>
        </Tooltip>
      </div>
    ),
    onPaginationChange: setPagination,
    state: { isLoading, rowSelection, pagination },
    enableColumnResizing: true,
    initialState: {
      density: 'compact',
      columnVisibility: {
        render_id: false,
        ml_id: false,
        created_at: false,
        created_by: false,
      },
    },
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MaterialReactTable table={table} />
    </LocalizationProvider>
  );
};

export default TasksTable;
