import React, { useEffect, useMemo, useState } from 'react';
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  MRT_PaginationState,
  MRT_TableOptions,
  useMaterialReactTable,
} from 'material-react-table';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { getColumnsByType, validateEntity } from '../Configuration';
import CachedIcon from '@mui/icons-material/Cached';
import { EntityType } from '../../../configs';
import AddIcon from '@mui/icons-material/Add';

interface EntityTableProps {
  data: any[];
  entityType: EntityType;
  reloadEntities: () => void;
  loading: boolean;
  addNewEntity: (data: any) => void;
  editingEntity: (data: any) => void;
  deleteEntities: (entitiesIds: string[]) => void;
}

const EntityTable = (props: EntityTableProps) => {
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
  const data = useMemo(() => props.data, [props.data]);
  const isLoading = useMemo(() => props.loading, [props.loading]);
  const [isSaving, setIsSaving] = useState(false);
  const [showProgressBars, setShowProgressBars] = useState(false);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    setRowSelection({});
  }, [props.data]);

  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => {
      let columns: MRT_ColumnDef<any>[] = [];
      getColumnsByType(props.entityType).forEach((column) => {
        let tmpColumn = column;
        if (tmpColumn.accessorKey && tmpColumn.muiEditTextFieldProps) {
          tmpColumn.muiEditTextFieldProps = {
            ...tmpColumn.muiEditTextFieldProps,
            error: !!validationErrors[tmpColumn.accessorKey],
            helperText: validationErrors[tmpColumn.accessorKey],
          };
        }
        columns.push(tmpColumn);
      });
      return columns;
    },
    // eslint-disable-next-line
    [props, validationErrors],
  );

  const handleCreateEntity: MRT_TableOptions<any>['onCreatingRowSave'] =
    async ({ values, table }) => {
      const newValidationErrors = validateEntity(values, props.entityType);
      if (Object.values(newValidationErrors).some((error) => error)) {
        setValidationErrors(newValidationErrors);
        return;
      }

      setValidationErrors({});
      setShowProgressBars(true);
      setIsSaving(true);
      await props.addNewEntity(values);
      table.setCreatingRow(null);
      setShowProgressBars(false);
      setIsSaving(false);
    };

  const handleSaveEntity: MRT_TableOptions<any>['onEditingRowSave'] = async ({
    values,
    table,
  }) => {
    const newValidationErrors = validateEntity(values, props.entityType);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }

    setValidationErrors({});
    setShowProgressBars(true);
    setIsSaving(true);
    await props.editingEntity(values);
    table.setEditingRow(null);
    setShowProgressBars(false);
    setIsSaving(false);
  };

  const openDeleteConfirmModal = async () => {
    setShowProgressBars(true);
    if (window.confirm('Are you sure you want to delete this row?')) {
      await props.deleteEntities(Object.keys(rowSelection));
      setShowProgressBars(false);
    }
  };

  const table = useMaterialReactTable({
    columns,
    data,
    createDisplayMode: 'row',
    editDisplayMode: 'row',
    enableEditing: true,
    onCreatingRowSave: handleCreateEntity,
    onCreatingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveEntity,
    onEditingRowCancel: () => setValidationErrors({}),
    enableRowActions: true,
    muiTableContainerProps: {
      sx: {
        minHeight: '500px',
      },
    },
    renderRowActions: ({ row, table }) => (
      <Tooltip title="Edit">
        <IconButton onClick={() => table.setEditingRow(row)}>
          <EditIcon />
        </IconButton>
      </Tooltip>
    ),
    enableRowSelection: (row) => row.original.preview !== 'processing',
    onRowSelectionChange: setRowSelection,
    getRowId: (row) => row.id,
    positionToolbarAlertBanner: 'bottom',
    renderTopToolbarCustomActions: ({ table }) => (
      <div>
        <Tooltip arrow title="Refresh Entities">
          <IconButton onClick={() => props.reloadEntities()}>
            <CachedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="Create new entity">
          <IconButton onClick={() => table.setCreatingRow(true)}>
            <AddIcon />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="Delete rows">
          <IconButton
            onClick={openDeleteConfirmModal}
            disabled={Object.keys(rowSelection).length === 0}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </div>
    ),

    onPaginationChange: setPagination,
    state: {
      isLoading,
      isSaving,
      rowSelection,
      pagination,
      showProgressBars,
    },
    enableColumnResizing: true,
    initialState: {
      density: 'compact',
    },
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MaterialReactTable table={table} />
    </LocalizationProvider>
  );
};

export default EntityTable;
