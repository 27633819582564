import React from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import { ListSubheader } from '@mui/material';
import Header from '../Header';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import TuneIcon from '@mui/icons-material/Tune';

interface SettingsProps {
  userName: string;
  isAdmin: boolean;
}

const drawerWidth = 240;

const Settings = (props: SettingsProps) => {
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <Container>
      <Header
        userName={props.userName}
        name={'Settings'}
        isAdmin={props.isAdmin}
      />
      <SchedulingContainer>
        <Box sx={{ display: 'flex' }}>
          <Drawer
            variant="permanent"
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              [`& .MuiDrawer-paper`]: {
                width: drawerWidth,
                boxSizing: 'border-box',
                display: 'contents',
              },
            }}
          >
            <Box sx={{ overflow: 'auto' }}>
              <List
                sx={{
                  width: '100%',
                  maxWidth: 360,
                  bgcolor: 'background.paper',
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    Pipeline configuration
                  </ListSubheader>
                }
              >
                <ListItemButton onClick={handleClick}>
                  <ListItemIcon>
                    <TuneIcon />
                  </ListItemIcon>
                  <ListItemText primary="Configurations" />
                  {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <AutoAwesomeIcon />
                      </ListItemIcon>
                      <ListItemText primary="Presets" />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <AutoAwesomeIcon />
                      </ListItemIcon>
                      <ListItemText primary="Pipelines" />
                    </ListItemButton>
                  </List>
                </Collapse>
              </List>
            </Box>
          </Drawer>
          <Divider />
        </Box>
      </SchedulingContainer>
    </Container>
  );
};

const Container = styled.div`
  margin: auto;
  max-width: 1440px;
`;

const SchedulingContainer = styled.div`
  padding: 1%;
  height: 700px;

  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-radius: 10px;
  display: flex;
`;
export default Settings;
