import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Runs from '../Runs';
import ConfigurationContext from '../../context/ConfigurationContext';
import { ConfigurationContextType } from '../../context/Types/ConfigurationContextType';
import LoadingIcon from '../Controls/LoadingIcon';
import styled from 'styled-components';
import Settings from '../Settings';
import Renders from '../Renders';
import CreatePipelineTask from '../Systems/Register';
import Tasks from '../Tasks';
import Entities from '../Entities';

interface ProdPipelineProps {
  userName: string;
  isAdmin: boolean;
}

const ProdPipeline = (props: ProdPipelineProps) => {
  const { configurationLoading } = useContext(
    ConfigurationContext,
  ) as ConfigurationContextType;

  if (configurationLoading) {
    return (
      <LoadingContainer>
        <LoadingIcon />
      </LoadingContainer>
    );
  } else
    return (
      <Router>
        <Routes>
          {props.isAdmin && (
            <Route
              path="/"
              element={
                <Runs userName={props.userName} isAdmin={props.isAdmin} />
              }
            />
          )}
          {props.isAdmin && (
            <Route
              path="/settings"
              element={
                <Settings userName={props.userName} isAdmin={props.isAdmin} />
              }
            />
          )}
          <Route
            path="/renders"
            element={
              <Renders userName={props.userName} isAdmin={props.isAdmin} />
            }
          />
          {props.isAdmin && (
          <Route
            path="/entitys"
            element={
              <Entities userName={props.userName} isAdmin={props.isAdmin} />
            }
          />
          )}
          {props.isAdmin && (
            <Route
              path="/tasks"
              element={
                <Tasks userName={props.userName} isAdmin={props.isAdmin} />
              }
            />
          )}
          <Route
            path="/createTasks"
            element={
              <CreatePipelineTask
                userName={props.userName}
                isAdmin={props.isAdmin}
              />
            }
          />
        </Routes>
      </Router>
    );
};

const LoadingContainer = styled.div`
  min-height: 775px;
  display: grid;
`;

export default ProdPipeline;
