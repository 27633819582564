import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Header from '../Header';
import { Grid } from '@mui/material';
import SelectControl from '../Controls/SelectControl';
import EntityTable from '../Tables/EntityTable';
import ProdPipelineApi from '../../api/ProdPipelineApi';
import { EntityType } from '../../configs';

interface EntitiesProps {
  userName: string;
  isAdmin: boolean;
}

const Entities = (props: EntitiesProps) => {
  const [selectedEntityType, setSelectedEntityType] = useState(
    EntityType.BODY_IDENTITY,
  );
  const [dataItems, setDataItems] = useState<Map<string, any>>(new Map());
  const [entitiesLoading, setEntitiesLoading] = useState(false);

  const loadEntities = async () => {
    setEntitiesLoading(true);
    const entitiesResponse = await ProdPipelineApi.getEntities(
      selectedEntityType.key,
    );
    if (entitiesResponse.data) {
      const responseEntities = entitiesResponse.data.entities;
      if (responseEntities) {
        setDataItems(new Map(responseEntities.map((obj) => [obj.id, obj])));
      }
    }
    setEntitiesLoading(false);
  };

  useEffect(() => {
    if (selectedEntityType) loadEntities();
    // eslint-disable-next-line
  }, [selectedEntityType]);

  const deleteEntity = async (entitiesIds: string[]) => {
    const responseDelete = await ProdPipelineApi.deleteEntity(
      selectedEntityType.key,
      entitiesIds,
    );
    if (responseDelete.data && responseDelete.data.ids) {
      const deleteResponse: string[] = responseDelete.data.ids;
      let tempDataItems = new Map(dataItems);
      deleteResponse.forEach((entityId) => {
        tempDataItems.delete(entityId);
      });
      setDataItems(tempDataItems);
    }
  };

  const addNewEntity = async (newEntityData: any) => {
    newEntityData['type'] = selectedEntityType.displayName;
    const response = await ProdPipelineApi.createEntity(
      selectedEntityType.key,
      newEntityData,
      props.userName,
    );
    if (response.data && response.data.entity) {
      const responseEntity = response.data.entity;
      setDataItems(new Map(dataItems).set(responseEntity.id, responseEntity));
    }
  };

  const editingEntity = async (editeData: any) => {
    const responseEditing = await ProdPipelineApi.editeEntity(
      selectedEntityType.key,
      editeData,
      props.userName,
    );
    if (responseEditing.data && responseEditing.data.entity) {
      const editingResponse = responseEditing.data.entity;
      setDataItems(new Map(dataItems).set(editingResponse.id, editingResponse));
    }
  };

  return (
    <Container>
      <Header
        userName={props.userName}
        name={'Entities'}
        isAdmin={props.isAdmin}
      />
      <RendersContainer>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <SelectControl
              id={'entityType'}
              value={selectedEntityType.displayName}
              items={Object.values(EntityType).map(
                (entity) => entity.displayName,
              )}
              description={'Entity Table Type'}
              disabled={false}
              canBeEmpty={false}
              onChange={(field) => {
                for (const key of Object.keys(
                  EntityType,
                ) as (keyof typeof EntityType)[]) {
                  const entity = EntityType[key];
                  if (entity.displayName === field.value) {
                    setSelectedEntityType(entity);
                  }
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <EntityTable
              data={Array.from(dataItems.values())}
              reloadEntities={loadEntities}
              loading={entitiesLoading}
              addNewEntity={addNewEntity}
              editingEntity={editingEntity}
              deleteEntities={deleteEntity}
              entityType={selectedEntityType}
            />
          </Grid>
        </Grid>
      </RendersContainer>
    </Container>
  );
};

const Container = styled.div`
  margin: auto;
  max-width: 1440px;
`;

const RendersContainer = styled.div`
  padding: 1%;

  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-radius: 10px;
`;

export default Entities;
