import { Alert, Snackbar } from '@mui/material';
import React from 'react';

interface InfoControlProps {
  isOpen: boolean;
  message: string;
  setIsOpen: { (selection: boolean): void };
  type: 'success' | 'warning' | 'error';
}

const InfoControl = (props: InfoControlProps) => {
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    props.setIsOpen(false);
  };

  return (
    <Snackbar open={props.isOpen} autoHideDuration={5000} onClose={handleClose}>
      <Alert severity={props.type} onClose={handleClose}>
        {props.message}
      </Alert>
    </Snackbar>
  );
};

export default InfoControl;
