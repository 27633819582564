import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { Logout } from '@mui/icons-material';
import SettingsIcon from '@mui/icons-material/Settings';
import React from 'react';
import styled from 'styled-components';
import { Auth } from 'aws-amplify';

interface HeaderProps {
  userName: string;
  isAdmin: boolean;
  name: string;
}

const Header = (props: HeaderProps) => {
  const [anchorElAccount, setAnchorElAccount] =
    React.useState<null | HTMLElement>(null);
  const [anchorElNavigation, setAnchorElNavigation] =
    React.useState<null | HTMLElement>(null);
  const openAccount = Boolean(anchorElAccount);
  const openNavigation = Boolean(anchorElNavigation);
  const handleClickAccount = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElAccount(event.currentTarget);
  };
  const handleCloseAccount = () => {
    setAnchorElAccount(null);
  };
  const handleClickNavigation = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorElNavigation(event.currentTarget);
  };
  const handleCloseNavigation = () => {
    setAnchorElNavigation(null);
  };

  const signOut = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    Auth.signOut().then(() => window.location.reload());
  };
  return (
    <Container>
      <HeaderWrapper>
        {props.isAdmin ? (
          <Navigation>
            <Button
              id="basic-button"
              color={'inherit'}
              aria-controls={openNavigation ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openNavigation ? 'true' : undefined}
              onClick={handleClickNavigation}
            >
              {props.name}
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorElNavigation}
              open={openNavigation}
              onClose={handleCloseNavigation}
              onClick={handleCloseNavigation}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem component={Link} to="/">
                Prod Pipeline
              </MenuItem>
              <Divider />
              <MenuItem component={Link} to="/renders">
                Renders
              </MenuItem>
              <Divider />
              <MenuItem component={Link} to="/tasks">
                Tasks
              </MenuItem>
              <MenuItem component={Link} to="/createTasks">
                Create tasks
              </MenuItem>
              <MenuItem component={Link} to="/entitys">
                Entities
              </MenuItem>
            </Menu>
          </Navigation>
        ) : (
          <Navigation>
            <Typography>{props.name}</Typography>
          </Navigation>
        )}

        <Typography variant="h4" gutterBottom>
          Prod Pipeline
        </Typography>
        <Account>
          <Box>
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClickAccount}
                size="large"
                aria-controls={openAccount ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openAccount ? 'true' : undefined}
              >
                <Avatar sx={{ width: 32, height: 32 }}>
                  {props.userName[0].toUpperCase()}
                </Avatar>
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorElAccount}
            id="account-menu"
            open={openAccount}
            onClose={handleCloseAccount}
            onClick={handleCloseAccount}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={signOut}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
            {props.isAdmin && (
              <MenuItem component={Link} to="/settings">
                <ListItemIcon>
                  <SettingsIcon fontSize="small" />
                </ListItemIcon>
                Settings
              </MenuItem>
            )}
          </Menu>
        </Account>
      </HeaderWrapper>
      <LineBreaker />
    </Container>
  );
};

const Container = styled.div``;

const Navigation = styled.div`
  flex: 1;
`;

const HeaderWrapper = styled.div`
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Account = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const LineBreaker = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  border-bottom: 1px solid black;
`;

export default Header;
