import React, { useCallback, useContext, useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import {
  Backdrop,
  BottomNavigation,
  BottomNavigationAction,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import SelectControl from '../../Controls/SelectControl';
import TextFieldControl from '../../Controls/TextFieldControl';
import styled from 'styled-components';
import {
  AnyObject,
  Components,
  DynamicComponentProps,
  HandleType,
} from '../../../configs';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import ProdPipelineApi from '../../../api/ProdPipelineApi';
import InfoControl from '../../Controls/InfoWindow';
import LoadingIcon from '../../Controls/LoadingIcon';
import ConfigurationContext from '../../../context/ConfigurationContext';
import { ConfigurationContextType } from '../../../context/Types/ConfigurationContextType';
import PresetControl from '../../Controls/PresetControl';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DialogControl from '../../Controls/DialowWindow';

interface NewRunProps {
  userName: string;
  closeModal: { (selection: boolean, reloadData: boolean): void };
  runId: string | null;
  isNewRun: boolean;
}

const NewRunModal = (props: NewRunProps) => {
  const {
    pipelineVersionLoading,
    getPipelineVersion,
    configuration,
    getPreset,
    addPreset,
    removePreset,
  } = useContext(ConfigurationContext) as ConfigurationContextType;
  const [selectedPipelineVersion, setSelectedPipelineVersion] = useState(
    configuration.default,
  );

  const [runDataLoading, setRunDataLoading] = useState(false);
  const [structure, setStructure] = useState<any[]>([]);
  const [state, setState] = useState({});
  const [defaultState, setDefaultState] = useState({});
  const [accordionsState, setAccordionsState] = useState({});
  const [dataCreated, setDataCreated] = useState(false);
  const [infoOpened, setInfoOpened] = useState(false);
  const [infoMessage, setInfoMessage] = useState('');
  const [infoType, setInfoType] = useState<'success' | 'warning' | 'error'>(
    'success',
  );
  const [dialogOpened, setDialogOpened] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [taskScheduling, setTaskScheduling] = useState(false);
  const [sourceLink, setSourceLink] = useState('');
  const [selectedPreset, setSelectedPreset] = useState<null | string>(null);
  const [presetInEditMode, setPresetInEditMode] = useState(false);
  const [selectedPresetPipelineVersion, setSelectedPresetPipelineVersion] =
    useState<null | string>(null);

  useEffect(() => {
    loadRunData();
    // eslint-disable-next-line
  }, []);

  const loadRunData = () => {
    if (props.runId) {
      setRunDataLoading(true);
      ProdPipelineApi.getRun(props.runId).then((runDataResponse) => {
        if (runDataResponse.data) {
          let tempRunData = runDataResponse.data.run_data;
          setState(tempRunData);
          setSourceLink(tempRunData.gdrive_link);
          setSelectedPipelineVersion(tempRunData.pipeline_version);
          setRunDataLoading(false);
        }
      });
    }
  };

  useEffect(() => {
    if (selectedPipelineVersion !== '') buildForm(selectedPipelineVersion);
    // eslint-disable-next-line
  }, [selectedPipelineVersion]);

  useEffect(() => {
    if (selectedPreset && configuration.presets.includes(selectedPreset)) {
      getPreset(selectedPreset).then((preset) => {
        setSelectedPresetPipelineVersion(preset.pipeline_version);
        setSelectedPipelineVersion(preset.pipeline_version);
        setState(preset.data);
      });
    }
    // eslint-disable-next-line
  }, [selectedPreset]);

  const buildForm = (pipelineKey: string) => {
    getPipelineVersion(pipelineKey).then((pipelineVersion) => {
      let tempExpandedModals = {};
      pipelineVersion.components.forEach((component: any) => {
        if (component.type === 'accordion') {
          // @ts-ignore
          tempExpandedModals[component.data.id] = component.data.expanded;
        }
      });
      setAccordionsState(tempExpandedModals);
      setStructure(pipelineVersion.components);
      if (!props.runId) {
        if (selectedPresetPipelineVersion !== pipelineKey) {
          setSelectedPresetPipelineVersion(null);
          setSelectedPreset(null);
          setState(pipelineVersion.data);
          setDefaultState(pipelineVersion.data);
        }
      }
    });
  };

  const render = (component: DynamicComponentProps, componentKey: number) => {
    let componentType = component.type;
    const TagName = Components[componentType as keyof AnyObject];
    let params = {};
    if (component.data.params) {
      component.data.params.forEach((param: HandleType) => {
        // @ts-ignore
        params[param.name] = state[param.value];
      });
    }
    if (componentType === 'accordion') {
      let componentId = component.data.id;
      // @ts-ignore
      params['expanded'] = accordionsState[componentId];
    }
    let handleFunction =
      componentType === 'accordion' ? handleExpand : handleOnChange;
    let renderedComponent = (
      <TagName {...component.data} {...params} onChange={handleFunction}>
        {component.children &&
          component.children.map((children, key) => {
            return <div key={key}>{render(children, key)}</div>;
          })}
      </TagName>
    );
    if (component.gridWrap) {
      return (
        <Grid item xs={12} key={componentKey}>
          {renderedComponent}
        </Grid>
      );
    }
    return renderedComponent;
  };

  const handleOnChange = (field: HandleType) => {
    setState((prevState) => ({ ...prevState, [field.name]: field.value }));
  };

  const handleExpand = (field: HandleType) => {
    setAccordionsState((prevState) => ({
      ...prevState,
      [field.name]: field.value,
    }));
  };

  const findPreset = () => {
    if (sourceLink !== '') {
      let indexFrom = sourceLink.indexOf('model_');
      if (indexFrom !== -1) {
        let modelName = sourceLink.substring(
          indexFrom,
          sourceLink.indexOf('/', indexFrom),
        );
        if (configuration.presets.includes(modelName)) {
          setSelectedPreset(modelName);
        } else {
          showMessage('Preset "' + modelName + '" not found!', 'warning');
          setSelectedPreset(null);
        }
      } else {
        showMessage('Preset not found!', 'warning');
        setSelectedPreset(null);
      }
    }
  };

  const resetModal = () => {
    setSelectedPipelineVersion(configuration.default);
    setSelectedPreset(null);
    setState(defaultState);
    setPresetInEditMode(false);
  };

  const showMessage = (
    message: string,
    type: 'success' | 'warning' | 'error',
  ) => {
    setInfoMessage(message);
    setInfoType(type);
    setInfoOpened(true);
  };

  const handleKeyPress = useCallback(
    (event: any) => {
      if (event.keyCode === 13 && event.ctrlKey) registerRun();
    },
    // eslint-disable-next-line
    [sourceLink, state, selectedPipelineVersion, selectedPreset],
  );

  useEffect(() => {
    // attach the event listener
    document.addEventListener('keydown', handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  const scheduleRun = async () => {
    let runData = {};
    Object.keys(state).forEach((key: string) => {
      if (!key.includes('Expanded') && key !== 'preset') {
        // @ts-ignore
        runData[key] = state[key];
      }
    });

    if (props.isNewRun) {
      // @ts-ignore
      delete runData['sk'];
    }

    // @ts-ignore
    runData['gdrive_link'] = sourceLink;
    try {
      let response = await ProdPipelineApi.scheduleRun(
        runData,
        selectedPipelineVersion,
        props.userName,
      );
      if (response.status !== 200) return response.statusText;
      if (response.data && response.data.statusCode !== 200)
        return response.data.error;
      return null;
    } catch (ex) {
      // @ts-ignore
      return ex.message;
    }
  };

  const savePreset = () => {
    if (
      selectedPreset &&
      (!configuration.presets.includes(selectedPreset) || presetInEditMode)
    )
      addPreset(selectedPreset, state, selectedPipelineVersion);
  };

  const deletePreset = () => {
    if (selectedPreset) {
      setSelectedPreset(null);
      removePreset(selectedPreset);
    }
  };

  const registerRun = () => {
    if (sourceLink.length === 0) {
      showMessage('GDrive link is empty', 'warning');
    } else {
      (async () => {
        setTaskScheduling(true);
        savePreset();
        scheduleRun()
          .then((errorMessage: string | null) => {
            if (errorMessage) {
              showMessage(errorMessage, 'error');
            } else {
              setDataCreated(true);
              showMessage('Task scheduled', 'success');
            }
          })
          .catch((ex) => {
            showMessage(ex.message, 'error');
          })
          .finally(() => {
            setTaskScheduling(false);
            setPresetInEditMode(false);
          });
      })();
    }
  };

  const closeModal = () => {
    props.closeModal(false, dataCreated);
    resetModal();
    setDataCreated(false);
  };

  return (
    <Modal open={true} onClose={closeModal}>
      <BoxContainer>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={taskScheduling}
        >
          <LoadingIcon />
        </Backdrop>
        <InfoControl
          isOpen={infoOpened}
          message={infoMessage}
          setIsOpen={setInfoOpened}
          type={infoType}
        />
        <DialogControl
          isOpen={dialogOpened}
          title={'Remove preset'}
          description={dialogMessage}
          setIsOpen={setDialogOpened}
          approveAction={deletePreset}
        ></DialogControl>
        {runDataLoading ? (
          <LoadingIcon />
        ) : (
          <ModalContainer>
            <GridContainer>
              <Grid container spacing={1}>
                <Grid item xs={12} sx={{ paddingBottom: '1%' }}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Register new run
                  </Typography>
                </Grid>
                <Grid item xs={5.5}>
                  <SelectControl
                    id={'pipelineVersion'}
                    value={selectedPipelineVersion}
                    items={configuration.pipeline_versions}
                    description={'Pipeline version'}
                    disabled={false}
                    canBeEmpty={false}
                    onChange={(field) => {
                      setSelectedPipelineVersion(field.value);
                    }}
                  />
                </Grid>
                <Grid item xs={5.5}>
                  <PresetControl
                    items={configuration.presets}
                    selectedPreset={selectedPreset}
                    setSelectedPreset={setSelectedPreset}
                    disabled={presetInEditMode}
                  />
                </Grid>
                <Grid item xs={0.5} style={{ textAlign: 'center' }}>
                  <FormControl>
                    <FormHelperText> </FormHelperText>
                    <IconButton
                      disabled={
                        selectedPreset === null ||
                        !configuration.presets.includes(selectedPreset)
                      }
                      onClick={() => {
                        setPresetInEditMode(!presetInEditMode);
                      }}
                    >
                      <EditIcon fontSize="inherit" />
                    </IconButton>
                  </FormControl>
                </Grid>
                <Grid item xs={0.5} style={{ textAlign: 'center' }}>
                  <FormControl>
                    <FormHelperText> </FormHelperText>
                    <IconButton
                      disabled={
                        selectedPreset === null ||
                        presetInEditMode ||
                        !configuration.presets.includes(selectedPreset)
                      }
                      onClick={() => {
                        setDialogMessage(
                          'Are you sure you want to delete preset "' +
                            selectedPreset +
                            '"?',
                        );
                        setDialogOpened(true);
                      }}
                    >
                      <DeleteIcon fontSize="inherit" />
                    </IconButton>
                  </FormControl>
                </Grid>
                <Grid item xs={10.5}>
                  <TextFieldControl
                    value={sourceLink}
                    onChange={(field) => {
                      setSourceLink(field.value);
                    }}
                    id={'gdrive_link'}
                    description={'Gdrive link'}
                  />
                </Grid>
                <Grid item xs={1.5}>
                  <FormControl style={{ width: '100%' }}>
                    <FormHelperText> </FormHelperText>
                    <Button
                      variant="contained"
                      color="inherit"
                      style={{ height: '40px', width: '100%' }}
                      disabled={sourceLink === '' || presetInEditMode}
                      onClick={findPreset}
                    >
                      Find preset
                    </Button>
                  </FormControl>
                </Grid>
                {pipelineVersionLoading ? (
                  <LoadingIcon />
                ) : (
                  structure.map((component, key) => {
                    return render(component, key);
                  })
                )}
              </Grid>
            </GridContainer>
            <Navigation showLabels>
              <BottomNavigationAction
                label="Reset"
                icon={<RotateLeftIcon />}
                onClick={resetModal}
              />
              <BottomNavigationAction
                label="Create"
                icon={<AddIcon />}
                onClick={registerRun}
              />
              <BottomNavigationAction
                label="Close"
                icon={<CloseIcon />}
                onClick={closeModal}
              />
            </Navigation>
          </ModalContainer>
        )}
      </BoxContainer>
    </Modal>
  );
};

const BoxContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 90%;
  background-color: #fff;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 24px;
  outline: none;
`;

const ModalContainer = styled.div`
  overflow-y: scroll;
  height: 92%;
  display: flex;
`;
const GridContainer = styled.div``;

const Navigation = styled(BottomNavigation)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
`;

export default NewRunModal;
