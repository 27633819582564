import * as React from 'react';
import { useCallback } from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { Typography } from '@mui/material';

export interface UploadFileProps {
  setFilesToUpload: { (file: File[]): void };
  multiple: boolean;
  accept: any;
}

export const UploadFile = (props: UploadFileProps) => {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    props.setFilesToUpload(acceptedFiles);
    // eslint-disable-next-line
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    multiple: props.multiple,
    onDrop,
    accept: props.accept,
  });

  return (
    <Container {...getRootProps()}>
      <input {...getInputProps()} />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography>drag and drop files here</Typography>
        <Typography>- or -</Typography>
        <Typography>click to upload</Typography>
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
