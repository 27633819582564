import { Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';
import { HandleType } from '../../../configs';

interface CheckBoxControlProps {
  description: string;
  id: string;
  checked: boolean;
  onChange: { (field: HandleType): void };
}

const CheckBoxControl = (props: CheckBoxControlProps) => {
  const handleChange = () => {
    let field = {
      name: props.id,
      value: !props.checked,
    };
    props.onChange(field);
  };
  return (
    <FormControlLabel
      sx={{ '& .MuiSvgIcon-root': { fontSize: 38 } }}
      control={<Checkbox checked={props.checked} color="default" />}
      label={props.description}
      onChange={handleChange}
    />
  );
};

export default CheckBoxControl;
