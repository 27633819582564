import React from 'react';
import styled, { keyframes } from 'styled-components';

const icon = require('../../../assets/icon-180x180.png');

function blinkingEffect() {
  return keyframes`
    50% {
      opacity: 0.5;
    }
  `;
}

interface LoadingProps {
  size?: 'small';
}

const LoadingIcon = (props: LoadingProps) => {
  const size =
    props.size && props.size === 'small'
      ? { width: '34px', height: '34px' }
      : { width: '75px', height: '75px' };
  return (
    <Container>
      <AnimatedComponent>
        <Image src={icon} alt="img" style={size} />
      </AnimatedComponent>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AnimatedComponent = styled.div`
  animation: ${blinkingEffect} 2s linear infinite;
`;

const Image = styled.img``;

export default LoadingIcon;
