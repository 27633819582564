import React, { useEffect, useMemo, useState } from 'react';
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  MRT_PaginationState,
  useMaterialReactTable,
} from 'material-react-table';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { IconButton, Tooltip } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { RunDto } from '../../../api/types/GetRunsResponseDto';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { ContentCopy } from '@mui/icons-material';

interface RunTableProps {
  isLoading: boolean;
  data: Map<string, RunDto>;
  rowCount: number;
  reloadData: (fetchMore?: boolean) => void;
  deleteRuns: () => void;
  createRun: () => void;
  restartRuns: () => void;
  setSelectedRuns: { (selectedIds: string[]): void };
  disabledDelete: boolean;
  disabledEdit: boolean;
  disableRestart: boolean;
}

const RunTable = (props: RunTableProps) => {
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
  const data = useMemo(() => Array.from(props.data.values()), [props.data]);
  const isLoading = useMemo(() => props.isLoading, [props.isLoading]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });

  useEffect(() => {
    if (
      (pagination.pageIndex + 1) * pagination.pageSize > props.data.size &&
      props.data.size > 0 &&
      props.rowCount === 9999
    ) {
      props.reloadData(true);
    }

    // eslint-disable-next-line
  }, [pagination]);

  useEffect(() => {
    props.setSelectedRuns(Object.keys(rowSelection));
    // eslint-disable-next-line
  }, [rowSelection]);

  useEffect(() => {
    setRowSelection({});
  }, [props.data]);

  const columns = useMemo<MRT_ColumnDef<RunDto>[]>(
    () => [
      {
        accessorKey: 'id', //access nested data with dot notation
        header: 'ID',
        size: 260,
      },
      {
        accessorKey: 'created_by', //normal accessorKey
        header: 'Created by',
        size: 180,
      },
      {
        accessorKey: 'created_at',
        size: 160,
        accessorFn: (originalRow) => new Date(originalRow.created_at),
        header: 'Created at',
        filterVariant: 'date',
        filterFn: 'greaterThan',
        enableGlobalFilter: false,
        Cell: ({ cell }) => new Date(cell.getValue<Date>()).toLocaleString(),
      },
      {
        accessorKey: 'source_link', //normal accessorKey
        header: 'Source link',
        size: 600,
        enableClickToCopy: true,
      },
      {
        accessorKey: 'run_args', //normal accessorKey
        header: 'Run args',
        size: 150,
        enableClickToCopy: true,
        Cell: ({ cell }) => (
          <Box title={cell.getValue<string>()} sx={{ textWrap: 'nowrap' }}>
            {cell.getValue<string>()}
          </Box>
        ),
        enableGlobalFilter: false,
      },
      {
        accessorKey: 'state',
        header: 'State',
        size: 150,
        filterVariant: 'multi-select',
        filterSelectOptions: ['created', 'processing', 'finished', 'failed'],
      },
      {
        accessorKey: 'started_at',
        size: 220,
        accessorFn: (originalRow) => new Date(originalRow.started_at),
        header: 'Started at',
        filterVariant: 'date',
        filterFn: 'greaterThan',
        enableGlobalFilter: false,
        Cell: ({ cell }) => new Date(cell.getValue<Date>()).toLocaleString(),
      },
      {
        accessorKey: 'finished_at',
        size: 220,
        accessorFn: (originalRow) => new Date(originalRow.finished_at),
        header: 'Finished at',
        filterVariant: 'date',
        filterFn: 'greaterThan',
        enableGlobalFilter: false,
        Cell: ({ cell }) => new Date(cell.getValue<Date>()).toLocaleString(),
      },
      {
        accessorKey: 'result_link',
        header: 'Result',
        size: 150,
        enableColumnFilter: false,
        Cell: ({ cell, row }) => (
          <Box>
            {row.getValue<string>('state') === 'finished' &&
              cell.getValue<string>() && (
                <Link
                  href={cell.getValue<string>()}
                  style={{ cursor: 'pointer', color: 'black' }}
                  target="_blank"
                >
                  Browse
                </Link>
              )}
          </Box>
        ),
        enableClickToCopy: true,
        muiCopyButtonProps: {
          fullWidth: true,
          startIcon: <ContentCopy />,
          sx: { justifyContent: 'flex-start' },
        },
        enableGlobalFilter: false,
      },
      {
        accessorKey: 'error',
        header: 'Error',
        size: 200,
        enableClickToCopy: true,
        Cell: ({ cell }) => (
          <Box sx={{ textWrap: 'nowrap' }}>{cell.getValue<string>()}</Box>
        ),
      },
      {
        accessorKey: 'outputs_path',
        header: 'Outputs path',
        size: 200,
        enableGlobalFilter: false,
      },
      {
        accessorKey: 'worker_id',
        header: 'Worker ID',
        size: 180,
      },
    ],
    // eslint-disable-next-line
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data,
    rowCount: props.rowCount,
    enableRowSelection: (row) => row.original.state !== 'processing',
    onRowSelectionChange: setRowSelection,
    getRowId: (row) => row.id,
    positionToolbarAlertBanner: 'bottom',
    renderTopToolbarCustomActions: () => (
      <div>
        <Tooltip arrow title="Refresh Data">
          <IconButton onClick={() => props.reloadData()}>
            <CachedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="Create run">
          <IconButton onClick={() => props.createRun()}>
            <AddIcon />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="Edit run">
          <span>
            <IconButton
              onClick={() => props.createRun()}
              disabled={props.disabledEdit}
            >
              <EditIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip arrow title="Restart runs">
          <span>
            <IconButton
              onClick={() => props.restartRuns()}
              disabled={props.disableRestart}
            >
              <RestartAltIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip arrow title="Delete runs">
          <span>
            <IconButton
              onClick={() => props.deleteRuns()}
              disabled={props.disabledDelete}
            >
              <DeleteIcon />
            </IconButton>
          </span>
        </Tooltip>
      </div>
    ),
    state: { isLoading, rowSelection, pagination },
    onPaginationChange: setPagination,
    enableColumnResizing: true,
    initialState: {
      columnVisibility: {
        renderId: false,
        started_at: false,
        finished_at: false,
      },
      columnPinning: { left: ['mrt-row-select', 'source_link'] },
      density: 'compact',
    },
    enableColumnPinning: true,
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MaterialReactTable table={table} />
    </LocalizationProvider>
  );
};

export default RunTable;
