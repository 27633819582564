import {
  FormControl,
  FormHelperText,
  Grid,
  Input,
  Slider,
} from '@mui/material';
import React from 'react';
import { HandleType } from '../../../configs';

interface SliderControlProps {
  description: string;
  id: string;
  value: number;
  min: number;
  max: number;
  step: number;
  onChange: { (field: HandleType): void };
}

const SliderControl = (props: SliderControlProps) => {
  const handleChange = (value: number) => {
    let field = {
      name: props.id,
      value: value,
    };
    props.onChange(field);
  };

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    handleChange(newValue as number);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(event.target.value === '' ? 0 : Number(event.target.value));
  };

  const handleBlur = () => {
    if (props.value < props.min) {
      handleChange(props.min);
    } else if (props.value > props.max) {
      handleChange(props.max);
    }
  };

  return (
    <FormControl sx={{ maxWidth: 1000, minWidth: 400, width: 1000 }}>
      <FormHelperText>{props.description}</FormHelperText>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <Slider
            sx={{
              '& .MuiSlider-thumb': {
                color: '#9e9e9e',
              },
              '& .MuiSlider-track': {
                color: '#9e9e9e',
              },
              '& .MuiSlider-rail': {
                color: '#9e9e9e',
              },
              '& .MuiSlider-active': {
                color: '#9e9e9e',
              },
            }}
            value={props.value}
            min={props.min}
            max={props.max}
            step={props.step}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
          />
        </Grid>
        <Grid item>
          <Input
            value={props.value}
            size="small"
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              step: props.step,
              min: props.min,
              max: props.max,
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
        </Grid>
      </Grid>
    </FormControl>
  );
};

export default SliderControl;
